.btn {
    text-align:center; 
    border-radius: .08rem;
    border-width: .04rem;
    border-style: hidden;
    border-color: transparent;
    box-sizing: border-box;
    font-size: .26rem;
    padding: .26rem .375rem;
    display: inline-block;
    text-transform: uppercase;
    // margin: 0 .2rem;
    &-default {
        box-shadow: inset 0 .015rem 0 rgba(255,255,255,.15), 0 .02rem .05rem rgba(0,0,0,.5);
        text-shadow: 0 -.01rem .01rem rgba(0,0,0,.5);
        background-color: $gray;
        color: $white;
        &:active {
            background: $mid;
            box-shadow: inset 0 .03rem .04rem rgba(0,0,0,.5);
            color: #ccc;
        }
    }
    &-gold {
        color: $dark;
        @extend .gold-grad;
        text-shadow: 0 .01rem 0 rgba(255,255,255,.6);
        box-shadow: inset 0 .015rem 0 rgba(255,255,255,.6), 0 .14rem .3rem #000;
        &:active {
            @extend .gold-grad-invert;
        }
    }
    &-game-menu {
        padding: .1rem;
        width: 70%;
        margin-left: .22rem;
        margin-bottom: .1rem;
    }
    &-outline {
        color: $gold;
        border-color: $gold;
        border-style: solid;
        background-color: transparent;
        padding: .22rem .335rem;
        box-shadow: none;
        color: $white;
        &:active {
            background:rgba(0,0,0,0.5);
        }
    } 
    &-blank {
        background: none;
        border: none;
        color: $white;
        box-shadow: none;
    }
    &-block {
        display: block;
    }
    &-group {
        display: flex;
        .btn {
            padding-left: 0;
            padding-right: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow:ellipsis;
            flex: 1;
            position: relative;
            z-index: 9;
            &-gold {
                box-shadow: inset 0 .015rem 0 rgba(255,255,255,.6), 0 .05rem .1rem rgba(0,0,0,.5);
            }
            &.active {
                z-index: 10;
            }
        }
        .btn:first-child {
            border-radius: .08rem 0 0 .08rem;
        }
        .btn:last-child {
            border-radius: 0 .08rem .08rem 0;
            
        }
    }

    &-full {
        display: flex;
        .btn {
            padding-left: 0;
            padding-right: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow:ellipsis;
            flex: 1;
            position: relative;
            z-index: 9;
            &-gold {
                box-shadow: inset 0 .015rem 0 rgba(255,255,255,.6), 0 .05rem .1rem rgba(0,0,0,.5);
            }
            &.active {
                z-index: 10;
            }
        }
    }   
}
.modal .actions .cell .btn {
    margin: 0 .1rem;
}