#app.view-scores {
    #game-players {
        transform: translateX(-2.75rem);
    }
    #overlay {
        transform: translateX(0);
        
    }
}

// .btn-save {
//     width: 3.2rem !important;
// }
.scoreboard {
    position: absolute;
    left: 0;
    top: .3rem;
    height: calc(100vh - .3rem);
    width: 100vw;
    display:flex;
    flex-direction: column;
    
    .score-grid {
        flex: 1;
    }
    .actions {
        flex: 0;
        text-align:center;
        font-size: .1rem;
        padding: .35rem 0;
        .btn {
            width: 3rem;
            margin: 0 .15rem;
        }

        .btn-save {
            width: 3.1rem;
            margin: 0 .15rem;
        }
    }
    &-league {
        transform: translateX(calc(-100vw - .3rem));
        transition: transform 1s ease;
        will-change: transform;
        &.active {
            transform: translateX(0);
        }
        table {
            font-size: .3rem;
            color: $white; 
            width: 100%;

            &.first-21 {
                width: 50%;
                margin: 0 auto;
            }
            
            tr {
                height: .57rem;
            }
            .midway {
                
                border-right: .05rem solid $white;
                
            }
            .final {
                
                border-left: .05rem solid $white;
            
            }
            th {
                font-weight: normal;
            }
            thead {
                th {
                    border: none;
                }
            }
            td, th {
                border: .01rem solid $white;
                padding: 0rem .07rem;
                vertical-align: middle;
            }
            td {
                text-align:center;

                &.td-bl {
                    // TODO: Swap for larger resolution
                    // On larger resolution
                    // width: 200px;

                    // width: 150px;
                    padding: 0;
                    width: 1.2rem;
                }

                &.td-ls {
                    width: 75px;
                }

                &.td-mn {
                    width: 151px;
                    padding: 0;
                }
                
            }
            .team {
                th {
                    width: 2.25rem;
                    color: $gold;
                    font-size: .25rem;
                    padding-left: .15rem;
                }
                th, td {
                    border-top: .03rem solid $white;
                    border-bottom: .03rem solid $white;
                }
                td {
                    background: rgba(255,255,255,.29);
                }
            }
            .player th {
                width: 2.0rem;
                font-size: .25rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: .15rem;
            }
            tbody th {
                text-align:left;
                border-left: none;
                border-right: .06rem solid $white;

                &.first-21 {
                    border-left: 0.01rem solid #fff;
                }
            }
            tbody + tbody {
                border-top: .08rem solid $white;
            }
        }
    }
}

.gray-bg { 
    background: rgba(255, 255, 255, 0.29);
}

.scoreboard-21 {
    min-width: 22%;
    display: inline-block;
    margin: .1rem;

    .second-row {
        margin-top: -2rem;
    }

    &table {
        margin-bottom: 200px;
        width: 100%;
        text-align: center;
    }

    .score-row {
        text-align: center;
        border-left: 1px solid white;
        border-right: 1px solid white;
    }

    .score-header {
        border-right: 1px solid white;
        text-align: center;
    }

    .score {
        height: 1rem;
        text-align: center;
        font-weight: bold;
        font-size: .5rem;
        padding: .2rem;
    }
}