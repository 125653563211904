* {
    -webkit-overflow-scrolling: touch;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}
.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
html, body, #app {
    font-size: 7.8125vw;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    transform: translate(0);
}
body {
    background: $white;
    color: $white;
    font-family: 'primeregular';
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
}


p, li, .btn {
    font-size: .26rem;
}
p, li {
    line-height: 1.4em;
    margin-bottom: .5em;
}
ul {
    padding-left: 1em;
    margin-bottom: 1em;
}
h1, h2 {
    text-align:center;
    font-family: 'primelight';
    margin-bottom: .35em;
    margin-top: 1em;
    &:first-child {
        margin-top: 0;
    }
}
h1 {
    font-size: .5rem;
}
h2 {
    font-size: .375rem;
}
small {
    font-size: .65em;
}
#axearcade {
    position:absolute;
    right: .20rem;
    bottom: .20rem;
    width: 1.35rem;
    img {
        max-width: 100%;
    }
}
#interface {
    position: absolute;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
}

.blur {
    height: 100vh;
    width: 100vw;
    background: rgba(255,205,150,.25);
   
    position: absolute;
    left: 0;
    top: 0;
    
    z-index: 9999999999;
    
}

.hidden {
    display: none;
}