$black: #000;
$gold: #FFC845;
$white: #fff;
$dark: #261C03;
$mid: #3C3C3C;
$gray: #5E5E5E;
$modal-bg: rgba(32,32,32,.8);
$blue: #0330C6;
$red: #C60303;
.trans-grad {
    // background: rgb(0,0,0);
    // background:    -moz-radial-gradient(bottom, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0.90) 77%, rgba(0,0,0,1) 100%);
    // background: -webkit-radial-gradient(bottom, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0.90) 77%, rgba(0,0,0,1) 100%);
    background:         radial-gradient(ellipse at bottom, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0.90) 77%, rgba(0,0,0,1) 100%);
    // background:            radial-gradient(bottom, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .9) 77%, rgba(0, 0, 0, 1) 100%);
    
}
.gold-grad {
    background: rgb(195,127,0);
    background: -moz-linear-gradient(0deg, rgba(195,127,0,1) 0%, rgba(255,200,69,1) 78%, rgba(255,214,117,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(195,127,0,1) 0%, rgba(255,200,69,1) 78%, rgba(255,214,117,1) 100%);
    background: linear-gradient(0deg, rgba(195,127,0,1) 0%, rgba(255,200,69,1) 78%, rgba(255,214,117,1) 100%);
    
}
.gold-grad-invert {
    background: rgb(195,127,0);
    background: -moz-linear-gradient(180deg, rgba(195,127,0,1) 0%, rgba(255,200,69,1) 78%, rgba(255,214,117,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(195,127,0,1) 0%, rgba(255,200,69,1) 78%, rgba(255,214,117,1) 100%);
    background: linear-gradient(180deg, rgba(195,127,0,1) 0%, rgba(255,200,69,1) 78%, rgba(255,214,117,1) 100%);
    
}

.red-background {
    background-color: red;
}

.blue-background {
    background-color: blue;
}