.mini-modal {
    border-radius: .20rem;
    background: $modal-bg;
    box-shadow: 0 .20rem .60rem $black;
    padding: .25rem;
    font-size: .26rem;
}

.modal {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    
    opacity: 0;
    visibility: hidden;
    transition: .75s ease opacity, .75s ease visibility;
    &.underlay {
        background: rgba(0,0,0,.65);
    }
    .modal-body {
        width: 70vw;
        height: 70vh;
        position: absolute;
        left: 50%;
        top: 50%;
        transform:translate(-50%,100%);
        transition: .75s ease transform;
        @extend .mini-modal;
        .modal-content {
            overflow: auto;
            height: calc(57vh - 1em);
            padding: 0 1em 1em;
        }
    }
    &.modal-instructions {
        .modal-body {
            display: flex;
            flex-direction: column;
            .modal-content {
                flex: 1;
            }
            .modal-actions {
                flex: 0;
            }
        }
    }
    .modal-actions {
        border-top: .01rem solid $gray;
        padding-top: .25rem;
        text-align:center;
        box-sizing: border-box;
        
        .btn {
            padding-left: .75rem;
            padding-right: .75rem;
        }
    }
    &.modal-keypad {
        .modal-body {
            width: auto;
            height: auto;
            padding-bottom: 0;
            .input-group {
                width: 2.85rem;
            }
            .keypad {
                display: grid;
                grid-template-columns: .85rem .85rem .85rem;
                //grid-template-rows: 1.8em; 
                column-gap: .15rem;
                row-gap: .15rem;
                justify-items: stretch;
                margin-bottom: .15rem;
                .btn {
                    align-self: stretch;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    &.active {
        opacity:1;
        visibility: visible;
        .modal-body {
            transform:translate(-50%,-50%);
        }
    }

    &.modal-end {
        
        .modal-body {
            @extend .trans-grad;
            height: auto;
            width: auto;
            text-align:center;
            padding-left: .5rem;
            padding-right: .5rem;
            padding-bottom: 0;
            margin-left: 0%;
            margin-top: -2%;
            h1, h2 {
                color: $gold;
                margin: 0;
            }
            h1 {
                font-size: .75rem;
                margin-bottom: .25em;
                text-transform: uppercase;
            }
            h2 {
                font-size: .35rem;
            }
        }
    }

    &.modal-score {
        
        .modal-body {
            @extend .trans-grad;
            height: auto;
            width: auto;
            text-align:center;
            padding-left: .5rem;
            padding-right: .5rem;
            padding-bottom: 0;
            margin-left: 10%;
            &.centered-modal {
                margin-left: 0%;
            }
            margin-top: -2%;
            h1, h2 {
                color: $gold;
                margin: 0;
            }
            h1 {
                font-size: .75rem;
                margin-bottom: .25em;
                text-transform: uppercase;
            }
            h2 {
                font-size: .35rem;
            }
        }
    }

    &.modal-score-1-8 {
        .modal-body {
            width: auto;
            height: auto;
            padding-bottom: 0.1rem;
        }
        .keypad {
            width:3.3rem;
            display: grid;
            grid-template-columns: calc(50% - .075rem) calc(50% - .075rem);
            column-gap: .15rem;
            row-gap: .15rem;
            margin-bottom: .15rem;
        }
        .actions {
            position: relative;
            .btn-close {
                margin-top: .15rem;
            }
        }
    }

    &.modal-score-21 {
        .modal-body {
            width: auto;
            height: auto;
            padding-bottom: 0.1rem;
        }
        .keypad {
            // width:3.3rem;
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            // grid-template-columns: calc(50% - .075rem) calc(50% - .075rem);
            column-gap: .15rem;
            row-gap: .15rem;
            margin-bottom: .15rem;

            &.small {

            }
        }
        .actions {
            position: relative;
            .btn-close {
                margin-top: .15rem;
            }
        }
    }
}

.on-top {
    z-index: 999;
}