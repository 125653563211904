@font-face {
    font-family: 'primelight';
    src: url('../fonts/prime_light-webfont.woff2') format('woff2'),
         url('../fonts/prime_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'primeregular';
    src: url('../fonts/prime_regular-webfont.woff2') format('woff2'),
         url('../fonts/prime_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}