#brand {
    position: absolute;
    left: 50%;
    // width: 2.95rem;
    // width: 4rem;
    height: 1rem;
    max-width: 4rem;
    transform:translate(-50%, .4rem);
    img {
        display: block;
        // width: 100%;
        // height: 100%;
        max-height: 100%;
        max-width: 100%
    }
}

#new-brand {
    margin: 0 auto;
    transform: translateX(-.18rem);
    padding-top: .15rem;
    padding-bottom: .12rem;

    width: calc(100% - 38px);
    img {
        // max-height: 41px;
        // max-width: 100%;
        // display: block;

        object-fit: contain;
        display: block;
        width: 100%;
        max-height: 70px;
    }
}