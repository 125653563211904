.spinning {
    animation-name: spinning;
    animation-duration: .75s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spinning {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}