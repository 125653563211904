.input-group {
    background: $mid;
    border-radius: .08rem;
    box-shadow: inset 0 .01rem 0 rgba(0,0,0,1), 0 .01rem 0 rgba(255,255,255,.25);
    padding: .1rem 0;
    margin-bottom: .15rem;
    label {
        display: block;
        color: $gold;
        font-size: .14rem;
        text-align:center;
        margin-bottom: .1rem;
    }
    input {
        width: 100%;
        box-sizing: border-box;
        font-size: .3rem;
        text-align:center;
        color: $white;
        background: none;
        border: none;
        outline: none;
        font-family: 'primeregular';
    }
}
.field-container {
    @extend .mini-modal;
    .input-group {
        margin-bottom: 0;
    }
}