#home {
    opacity: 1;
    visibility: visible;
    transition: opacity .5s ease, visibility .5s ease;
    #lane {
        font-size: 1.1rem;
        font-family: 'primelight';
        text-shadow: 0 .08rem .14rem $black;
        margin-top: 2rem;
        width: 100vw;
        text-align:center;
        margin-bottom: .75rem;
    }
    #browse-games-btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    &.inactive {
        visibility: hidden;
        opacity: 0;
    }
}